import React from 'react';
import { Link } from "react-router-dom";
import { Col, Row } from "antd-min";
import { GLUtil, GLGlobal, RoleName } from 'gl-commonui';
import { PathConfig } from '@app/config/pathconfig';
import { SchoolVisitationStatus } from '@app/service/coach/visitation';
import { LabelText } from './label-text';
import { SchoolLocale } from '@app/locales/localeid';

export interface SchoolStatusProps {
    status: SchoolVisitationStatus
}

export const SchoolStatus: React.FC<SchoolStatusProps> = ({ status }) => {
    const { regionId, schoolId, schoolName, onSiteTotal, lvaTotal, onSiteRemaining, lvaRemaining } = status ? status : {} as SchoolVisitationStatus;
    const pathSchools = regionId && schoolId ? GLUtil.pathStringify(PathConfig.Schools, { regionId, schoolId }) : null;
    const userHasRole = (role) => (GLGlobal.loginInfo().profile || { roles: [] }).roles.some(r => r == role);

    const isInternalUser = () => {
        return userHasRole(RoleName.regionAdmin) || userHasRole(RoleName.trainingAdmin) || userHasRole(RoleName.trainer) || userHasRole(RoleName.globalHead) || userHasRole(RoleName.systemAdmin) || userHasRole(RoleName.accountManager) || userHasRole(RoleName.trainingManager);
    }

    return (status ?
        <div className='school-status'>
            <Row type='flex'>
                <Col sm={24} md={8}>
                    <LabelText
                        labelId={SchoolLocale.SchoolVisitationHistorySchoolLabel}
                        text={schoolName}
                    >
                        {pathSchools ?
                            <Link className={'navigation-link'} to={pathSchools}>
                                {schoolName}
                            </Link>
                            : schoolName
                        }
                    </LabelText>
                </Col>
            </Row>
            <Row type='flex'>
                <Col sm={24} md={8}>
                    <LabelText
                        labelId={SchoolLocale.SchoolVisitationHistoryOnSiteVisitLabel}
                        text={onSiteTotal}
                    />
                </Col>
                <Col sm={24} md={8}>
                    <LabelText
                        labelId={SchoolLocale.SchoolVisitationHistoryLVALabel}
                        text={lvaTotal}
                    />
                </Col>
            </Row>
            {isInternalUser() && (<Row type='flex'>
                <Col sm={24} md={8}>
                    <LabelText
                        labelId={SchoolLocale.SchoolVisitationHistoryOnSiteRemainingLabel}
                        customClass={onSiteRemaining != Math.abs(onSiteRemaining) && "red"}
                        text={onSiteRemaining}
                    />
                </Col>
                <Col sm={24} md={8}>
                    <LabelText
                        labelId={SchoolLocale.SchoolVisitationHistoryLVARemainingLabel}
                        customClass={lvaRemaining != Math.abs(lvaRemaining) && "red"}
                        text={lvaRemaining}
                    />
                </Col>
            </Row>)}
        </div> : null
    )
}
