import React, {useState, useEffect} from 'react';
import { RouteComponentProps } from 'react-router';
import groupBy from 'lodash/groupBy';
import { Icon } from 'antd-min';
import { withRouter, maskThrottle, unmaskThrottle } from 'gl-commonui';
import { useService } from "@app/hooks";
import { MainTitle } from '@app/components';
import { TYPES } from "@app/service/types";
import { IVisitationService, SchoolVisitationHistories, SchoolVisitationStatus, CampusVisitationHistories, SchoolVisitationHistoryPropsModel } from '@app/service/coach/visitation';
import { SchoolLocale } from '@app/locales/localeid';
import { SchoolStatus } from './component/school-status';
import { HistoryTable } from './component/history-table';
import { stringCompare } from '@app/util';
import { LabelText } from './component/label-text';
import './style.less';

interface VisitationHistoryProps extends RouteComponentProps<any> { }

const VisitationHistory = (props: VisitationHistoryProps) => {

    const [visitationStatus, setVisitationStatus] = useState({} as SchoolVisitationStatus);
    const [lvaHistories, setLvaHistories] = useState({} as CampusVisitationHistories);
    const [onSiteHistories, setOnSiteistories] = useState([] as CampusVisitationHistories[]);

    const visitationService = useService<IVisitationService>(TYPES.IVisitationService);

    useEffect(() => {
        const { schoolId } = props.match.params;
        maskThrottle();
        visitationService.getSchoolReviewHistory(schoolId).then((result) => {
            formatVisitationHistories(result);
        }).catch(er => {
            unmaskThrottle();
        });
    }, []);

    const formatVisitationHistories = (visitation: SchoolVisitationHistories) => {
        let lvaHistories = [], onSiteHistories = [];
        setVisitationStatus(visitation.status);
        lvaHistories = visitation.histories.filter(v => !v.campusId).sort((a, b) => -a.startDate.localeCompare(b.startDate, 'en'));
        setLvaHistories({campus: null, histories: lvaHistories});
        const onSiteHistoryGroup = groupBy(visitation.histories.filter(v => v.campusId != null).sort((a, b)=> stringCompare(a.campusName, b.campusName)), 'campusId');
        Object.keys(onSiteHistoryGroup).forEach((campusId) => {
            const histories = onSiteHistoryGroup[campusId].sort((a, b) => -stringCompare(a[SchoolVisitationHistoryPropsModel.startDate], b[SchoolVisitationHistoryPropsModel.startDate], 'en'));
            if (histories.length > 0) {
                onSiteHistories.push({
                    campus: {
                        id: histories[0].campusId,
                        name: histories[0].campusName,
                        schoolId: histories[0].schoolId,
                        regionId: histories[0].regionId,
                    },
                    histories
                });
            }
        });
        setOnSiteistories(onSiteHistories);
    }

    const renderContent = () => {
        const noData = visitationStatus && onSiteHistories && onSiteHistories.length == 0 && lvaHistories && lvaHistories.histories && lvaHistories.histories.length == 0
        return (
            <div className='content-layout school-visitation school-visitation-history'>
                <MainTitle title={SchoolLocale.SchoolVisitationHistoryTitle} />
                <div className='page-content'>
                    <SchoolStatus status = {visitationStatus} />
                    {onSiteHistories && onSiteHistories.length > 0 &&
                    <div className='onSite-history'>
                        <LabelText
                            hasColon = {false}
                            icon = {<Icon type="environment" />}
                            labelId = {SchoolLocale.SchoolVisitationHistoryOnSiteTitleLabel}
                        />
                       {
                       onSiteHistories.map((campusHistory, index) => {
                            return (
                                <HistoryTable
                                    key = {index}
                                    campus = {campusHistory.campus}
                                    campusHistories = {campusHistory.histories}
                                />
                            )
                        })
                        }
                    </div>
                    }                    
                    {lvaHistories && lvaHistories.histories && lvaHistories.histories.length > 0 && 
                    <div className='lva-history'>
                        <LabelText
                            hasColon = {false}
                            icon = {<Icon type="video-camera" />}
                            labelId = {SchoolLocale.SchoolVisitationHistoryLVATitleLabel}
                        />
                        <HistoryTable 
                            campusHistories = {lvaHistories.histories}
                        />
                    </div>
                    }
                    {noData &&
                    <div className='no-history'>
                        <LabelText
                            hasColon = {false}
                            labelId = {SchoolLocale.SchoolVisitationHistoryNoDataTitleLabel}
                        />
                    </div>
                    }
                </div>
            </div>
        )
    }

    return renderContent();
}

export const VisitationHistoryPage = withRouter(VisitationHistory);